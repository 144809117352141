function ajax_request(method, url, data, done_callback, fail_callback, always_callback) {

    if (done_callback === undefined) done_callback = function () {};
    if (fail_callback === undefined) fail_callback = function () {};
    if (always_callback === undefined) always_callback = function () {};

    $.ajax({
        method: method,
        url: url,
        data: data
    })
        .done(done_callback)
        .fail(fail_callback)
        .always(always_callback)
}

function ajax_get(url, data, done_callback, fail_callback, always_callback) {
    ajax_request('GET', url, data, done_callback, fail_callback, always_callback)
}

function ajax_get_simple(url, data, done_callback) {
    ajax_get(url, data, done_callback)
}

function ajax_post(url, data, done_callback, fail_callback, always_callback) {
    ajax_request('POST', url, data, done_callback, fail_callback, always_callback)
}

function ajax_post_simple(url, data, done_callback) {
    ajax_post(url, data, done_callback)
}

function getAllUrlParams(url) {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        var arr = queryString.split('&');

        for (var i=0; i<arr.length; i++) {
            // separate the keys and the values
            var a = arr[i].split('=');

            // in case params look like: list[]=thing1&list[]=thing2
            var paramNum = undefined;
            var paramName = a[0].replace(/\[\d*\]/, function(v) {
                paramNum = v.slice(1,-1);
                return '';
            });

            // set parameter value (use 'true' if empty)
            var paramValue = typeof(a[1])==='undefined' ? true : a[1];

            // (optional) keep case consistent
            // paramName = paramName.toLowerCase();
            // paramValue = paramValue.toLowerCase();

            if (typeof paramValue === "boolean") continue;

            // if parameter name already exists
            if (obj[paramName]) {
                // convert value to array (if still string)
                if (typeof obj[paramName] === 'string') {
                    obj[paramName] = [obj[paramName]];
                }
                // if no array index number specified...
                if (typeof paramNum === 'undefined') {
                    // put the value on the end of the array
                    obj[paramName].push(paramValue);
                }
                // if array index number specified...
                else {
                    // put the value at that index number
                    obj[paramName][paramNum] = paramValue;
                }
            }
            // if param name doesn't exist yet, set it
            else {
                obj[paramName] = paramValue;
            }
        }
    }

    return obj;
}